import * as THREE from 'three'
import Stats from 'stats.js'
import { range } from 'lodash'

import './index.scss'
import { vertexShader, fragmentShader } from './shaders'

const webglAvailable = (() => {
  try {
    const canvas = document.createElement('canvas')
    return !!(
      window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    )
  } catch (e) {
    return false
  }
})()

const createAKMesh = () => {
  const akTexture = new THREE.TextureLoader().load('/static/images/AK-TIROL.png')
  akTexture.minFilter = THREE.LinearFilter
  const akMaterial = new THREE.MeshLambertMaterial({
    map: akTexture,
    depthTest: true,
    transparent: true
  })

  const aspectRatio = 1024 / 353 // ak logo dimensions 1024 x 353px
  const akGeometry = new THREE.PlaneGeometry(20, 20 / aspectRatio)
  return new THREE.Mesh(akGeometry, akMaterial)
}

export default {
  name: 'Clouds',
  render: h => (
    <div ref='clouds' class='clouds'></div>
  ),
  methods: {
    init () {
      if (!webglAvailable) return
      const cloudsContainer = this.$refs.clouds
      const CLOUDS_DEPTH = this.CLOUDS_DEPTH = 1000
      this.AK_MESH_POSITION_Z = -800
      this.stats = process.env.NODE_ENV === 'development' && new Stats()
      this.transitioning = false

      if (this.stats) {
        this.stats.showPanel(0)
        document.body.appendChild(this.stats.dom)
      }

      this.camera = new THREE.PerspectiveCamera(30, cloudsContainer.clientWidth / cloudsContainer.clientHeight, 0.1, 1000)
      this.camera.position.z = 0
      this.camera.position.y = 82
      this.camera.rotation.x = 0

      this.scene = new THREE.Scene()

      const texture = new THREE.TextureLoader().load('/static/images/cloud.png')
      const fog = new THREE.Fog(0x4584b4, -100, 3000)

      const material = new THREE.ShaderMaterial({
        uniforms: {
          'map': { type: 't', value: texture },
          'fogColor': { type: 'c', value: fog.color },
          'fogNear': { type: 'f', value: fog.near },
          'fogFar': { type: 'f', value: fog.far }
        },
        vertexShader,
        fragmentShader,
        depthWrite: true,
        depthTest: true,
        transparent: true
      })

      const clouds = range(0, CLOUDS_DEPTH).map(() => {
        const geometry = new THREE.Geometry()
        const plane = new THREE.Mesh(new THREE.PlaneGeometry(64, 64))
        plane.position.x = Math.random() * 1000 - 500 // [-500, 500]
        plane.position.y = -(Math.random() * Math.random() * 200 - 15) // [-185, 15]
        plane.rotation.z = Math.random() * Math.PI // [0, PI]
        plane.scale.x = plane.scale.y = Math.random() * Math.random() * 1.5 + 0.5 // [0.5, 2]
        plane.updateMatrix()
        geometry.merge(plane.geometry, plane.matrix)
        const cloudsBlock = new THREE.Mesh(geometry, material)
        cloudsBlock.position.y = 40
        return cloudsBlock
      })

      clouds.push(...clouds.map(c => c.clone()))

      clouds.forEach((c, i) => {
        c.position.z = -i
        this.scene.add(c)
      })

      const light = new THREE.PointLight(0xffffff, 1, 0)
      light.position.set(1, 1, 200)
      this.scene.add(light)

      this.renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true })
      this.renderer.setClearColor(0xffffff, 0)
      this.renderer.setSize(cloudsContainer.clientWidth, cloudsContainer.clientHeight)

      cloudsContainer.appendChild(this.renderer.domElement)
      requestAnimationFrame(this.animate)
    },
    render () {
      const position = (Date.now() - this.START_TIME) * this.speed
      const cameraPositionZ = -position % 1000
      if (this.transitioning && this.akMesh.position.z >= this.camera.position.z - 100) {
        this.resolveTransitionFinished && this.resolveTransitionFinished()
        delete this.resolveTransitionFinished
      }
      if (this.stats) this.stats.update()
      this.camera.position.z = cameraPositionZ
      this.renderer.render(this.scene, this.camera)
    },
    animate () {
      if (!this._isBeingDestroyed) requestAnimationFrame(this.animate)
      this.render()
    },
    startTransition () {
      if (webglAvailable) {
        this.transitioning = true
        this.START_TIME = Date.now()
        this.akMesh = createAKMesh()
        this.akMesh.position.y = 78
        this.akMesh.position.x = 10
        this.akMesh.position.z = this.AK_MESH_POSITION_Z
        this.scene.add(this.akMesh)
        return new Promise(resolve => {
          this.resolveTransitionFinished = resolve
        })
      }
      return Promise.resolve()
    }
  },
  mounted () {
    this.speed = 0.10
    this.START_TIME = Date.now()
    this.init()
  },
  beforeDestroy () {
    this.stats && this.stats.dom.remove()
  }
}
