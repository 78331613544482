<template>
  <div class="page page-type-welcome">
    <transition name="cloud-fade"
      v-on:after-leave="afterLeave"
    >
      <Clouds ref="clouds" v-if="!transitioned"/>
    </transition>
    <div class="welcome__banner" v-if="!transitioning">
      <div class="welcome__image">
        <img class="logo" src="/static/images/Logo.AK.Werkstatt.png" alt="Logo" title="Logo">
      </div>
      <div class="welcome__login">
        <form @submit.prevent="login">
          <div>
            <label for="token">Bitte Token eingeben:</label>
            <div class="input-button">
              <div class="input input-button__input">
                <input class="input__field" type="text" v-model="token" name="token" autocomplete="off" placeholder="Token" autofocus>
              </div>
              <div class="input-button__button">
                <button class="button button--color-blue" type="submit">
                  <span>Jetzt Starten</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SET_LOGIN_TOKEN } from '../store/mutation-types'
import Clouds from './Clouds'

export default {
  name: 'Login',
  data: () => ({
    transitioning: false,
    transitioned: false
  }),
  methods: {
    ...mapActions(['login']),
    afterLeave () {
      this.$router.push({ name: 'main' })
    }
  },
  components: {
    Clouds
  },
  computed: {
    transition () {
      return this.$store.state.login.transition
    },
    token: {
      get () {
        return this.$store.state.login.token
      },
      set (value) {
        return this.$store.commit(SET_LOGIN_TOKEN, value)
      }
    }
  },
  watch: {
    transition (newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.$refs.clouds
          .startTransition()
          .then(() => {
            setTimeout(() => {
              this.transitioned = true
            }, 500)
          })
        this.transitioning = true
      }
    }
  }
}
</script>

<style lang="scss">
$darkblue: #1f4465;
$blue: #559cd2;
$lightblue: #95c0e2;

.page-type-welcome {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.welcome {
  &__banner {
    width: 100%;
    background-color: rgba(248, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    label {
      display: block;
      font-size: 1.3rem;
      margin-bottom: 5px;
      font-weight: bold;
    }
    input {
      max-width: 150px;
      border-color: $lightblue;
    }
  }
  &__image {
    padding: 15px 0;
    img {
      margin-right: 50px;
    }
  }
}

.cloud-fade-leave-active {
  transition: all 2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.cloud-fade-leave-to {
  opacity: 0;
}

</style>
