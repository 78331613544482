export default {
  login: {
    token: '',
    error: null,
    transition: false
  },
  session: {
    namespace: null,
    state: {
      type: '',
      solution: null,
      slide: null,
      totalSlides: null,
      currentSlideIndex: null,
      slideTypes: []
    },
    team: {
      name: '',
      members: [
        { name: '', avatar: null },
        { name: '', avatar: null },
        { name: '', avatar: null }
      ]
    },
    slideStates: {}
  }
}
