import axios from 'axios'
import io from 'socket.io-client'

import { createSubscriber } from '@/common'

import {
  SET_LOGIN_TOKEN,
  SET_LOGIN_ERROR,
  START_TRANSITION,
  SET_SESSION_NAMESPACE,
  SET_STATE,
  SET_TEAM,
  RESET_SLIDE_STATES
} from './mutation-types'

const sockets = {
  socket: null,
  subscriptions: []
}

export default {
  login ({ state, commit }) {
    axios
      .post('/api/auth/student', {
        sessionToken: state.login.token
      })
      .then(response => {
        const { namespace } = response.data
        commit(START_TRANSITION)
        commit(SET_LOGIN_ERROR, null)
        commit(SET_SESSION_NAMESPACE, namespace)
      })
      .catch(() => {
        commit(SET_LOGIN_ERROR, 'Invalid Token')
        commit(SET_LOGIN_TOKEN, '')
      })
  },
  initSession ({ state, commit }) {
    const socket = io(state.session.namespace)

    socket.on('error', error => {
      if (error === 'Invalid namespace') {
        commit(SET_SESSION_NAMESPACE, null)
        commit(RESET_SLIDE_STATES)
      }
    })

    socket.on('disconnect', reason => {
      if (reason === 'io server disconnect') {
        commit(SET_SESSION_NAMESPACE, null)
        commit(RESET_SLIDE_STATES)
      }
    })

    socket.on('connect', () => {
      const subscribe = createSubscriber({ socket })
      const teamSubscription = subscribe('team').onUpdate(team =>
        commit(SET_TEAM, team)
      )
      const stateSubscription = subscribe('state').onUpdate(state =>
        commit(SET_STATE, state)
      )
      sockets.subscriptions = [teamSubscription, stateSubscription]
      sockets.socket = socket
    })
  },
  setTeam ({ commit }, team) {
    commit(SET_TEAM, team)
    sockets.socket.emit('submit-team', team)
  },
  commitAnswer (context, answer) {
    sockets.socket.emit('submit-answer', answer)
  }
}
