<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '@/common/styles/index.scss'
export default {
  name: 'App'
}
</script>

<style lang="scss"></style>
