import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import { Login, QuizStudent } from '../components'

Vue.use(Router)

function ifNotAuthenticated (to, from, next) {
  if (store.state.session.namespace) {
    next()
  } else {
    next({ name: 'login' })
  }
}

function ifAuthenticated (to, from, next) {
  if (store.state.session.namespace) {
    next({ name: 'main' })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  base: 'student',
  routes: [
    {
      path: '/',
      name: 'main',
      component: QuizStudent,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: ifAuthenticated
    }
  ]
})
