import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import state from './state.js'

Vue.use(Vuex)

const persistentStateOptions = {
  key: 'omk-student',
  paths: ['session.namespace', 'session.slideStates']
}

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  plugins: [createPersistedState(persistentStateOptions)],
  state,
  mutations,
  actions,
  getters
})

export default store
