export default {
  state: state => {
    const slideId = state.session.state.slide && state.session.state.slide.id
    const slideState = (slideId && state.session.slideStates[slideId]) || {}

    return {
      ...state.session.state,
      slideState
    }
  }
}
