<template>
  <Quiz v-bind="props"/>
</template>

<script>
import { Quiz } from '@/common'
import { mapState, mapGetters, mapActions } from 'vuex'
import { SET_SLIDE_STATE } from '../store/mutation-types'

export default {
  name: 'QuizStudent',
  components: { Quiz },
  created () {
    this.$store.dispatch('initSession')
  },
  methods: {
    ...mapActions(['setTeam']),
    handleAnswer (answer) {
      this.$store.dispatch('commitAnswer', answer)
    },
    handleSlideStateChange (state) {
      this.$store.commit(SET_SLIDE_STATE, {
        id: this.state.slide.id,
        state
      })
    }
  },
  computed: {
    ...mapState({
      sessionNamespace: state => state.session.namespace,
      team: state => state.session.team
    }),
    ...mapGetters([
      'state'
    ]),
    props () {
      return {
        state: this.state,
        team: this.team,
        setTeam: this.setTeam,
        handleSlideStateChange: this.handleSlideStateChange,
        handleAnswer: this.handleAnswer
      }
    }
  },
  watch: {
    sessionNamespace (namespace) {
      if (namespace === null) this.$router.go()
    }
  }
}
</script>
