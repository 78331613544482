// states
export const PREVIEW = 'PREVIEW'
export const SLIDE_INITIAL = 'SLIDE_INITIAL'
export const SLIDE_INTERACTIVE = 'SLIDE_INTERACTIVE'
export const SLIDE_PAUSED = 'SLIDE_PAUSED'
export const SLIDE_RESULT = 'SLIDE_RESULT'

// transitions
export const NEXT_SLIDE = 'NEXT_SLIDE'
export const NEXT_SLIDE_THEME = 'NEXT_SLIDE_THEME'
export const NEXT_SLIDE_PART = 'NEXT_SLIDE_PART'
export const START_SLIDE = 'START_SLIDE'
export const CANCEL_SLIDE = 'CANCEL_SLIDE'
export const PAUSE_SLIDE = 'PAUSE_SLIDE'
export const UNPAUSE_SLIDE = 'UNPAUSE_SLIDE'
export const FORCE_TIMEOUT_SLIDE = 'FORCE_TIMEOUT_SLIDE'
export const START_SLIDE_VIDEO = 'START_SLIDE_VIDEO'
