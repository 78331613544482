import {
  SET_LOGIN_TOKEN,
  SET_LOGIN_ERROR,
  START_TRANSITION,
  SET_SESSION_NAMESPACE,
  SET_STATE,
  SET_TEAM,
  SET_SLIDE_STATE,
  RESET_SLIDE_STATES
} from './mutation-types'

import Vue from 'vue'

export default {
  [SET_LOGIN_TOKEN] (state, token) {
    state.login.token = token
  },
  [SET_LOGIN_ERROR] (state, error) {
    state.login.error = error
  },
  [SET_SESSION_NAMESPACE] (state, namespace) {
    state.session.namespace = namespace
  },
  [SET_STATE] (state, s) {
    state.session.state = s
  },
  [SET_TEAM] (state, team) {
    state.session.team = team
  },
  [START_TRANSITION] (state) {
    state.login.transition = true
  },
  [SET_SLIDE_STATE] (state, { id, state: slideState }) {
    Vue.set(state.session.slideStates, id, slideState)
  },
  [RESET_SLIDE_STATES] (state) {
    state.session.slideStates = {}
  }
}
